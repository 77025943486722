<script setup lang="ts">
import {HeartIcon} from "@heroicons/vue/24/solid"
import {HeartIcon as HeartIconOutline} from "@heroicons/vue/24/outline"
import type {Project} from "~/interface";

const props = defineProps<{ full?: boolean, disabled?: boolean, instance: Project| null }>()
const CONTENT_TYPE = 9
const us = useUserStore()
const isVote = ref(props.instance?.is_vote)
const voteCount = ref(props.instance?.meta ? (props.instance?.meta["vote_count"] || 0) : 0)
const vote = async () => {
  if (us.isLogged) {
    const {data: res} = await useAuthFetch<number>('/activity/votes/', {
      method: "POST",
      body: {
        target_content_type: CONTENT_TYPE,
        target_object_id: props.instance?.id
      }
    })
    if (res.value) {
      isVote.value = voteCount.value < res.value
      voteCount.value = res.value
    }
  }
}

watch(() => props.instance?.is_vote, () => {
  isVote.value = props.instance?.is_vote
})
</script>

<template>
  <div
    class="flex gap-2 items-center"
    :class="{
      'text-gray-400': props.disabled,
      'cursor-pointer': !props.disabled
    }"
    @click="vote"
  >
    <HeartIcon v-if="isVote" class="w-4 h-4 text-blue-500"/>
    <HeartIconOutline v-else class="w-4 h-4"/>
    <span v-if="props.full" class="hidden md:block text-xs uppercase font-semibold">Upvote</span>
  </div>
</template>

<style scoped>

</style>
